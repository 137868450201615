@import "../css/variables.module";

.DRAW {
  .confirm-dialog {
    &-buttons {
      display: flex;
      column-gap: 0.5rem;
      justify-content: flex-end;
    }
  }
}
