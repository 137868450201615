@import "open-color/open-color.scss";
@import "./variables.module.scss";

.DRAW {
  --theme-filter: none;
  --button-destructive-bg-color: #{$oc-red-1};
  --button-destructive-color: #{$oc-red-9};
  --button-gray-1: #{$oc-gray-2};
  --button-gray-2: #{$oc-gray-4};
  --button-gray-3: #{$oc-gray-5};
  --button-special-active-bg-color: #{$oc-green-0};
  --dialog-border-color: var(--color-gray-20);
  --dropdown-icon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="292.4" height="292.4" viewBox="0 0 292 292"><path d="M287 197L159 69c-4-3-8-5-13-5s-9 2-13 5L5 197c-3 4-5 8-5 13s2 9 5 13c4 4 8 5 13 5h256c5 0 9-1 13-5s5-8 5-13-1-9-5-13z"/></svg>');
  --focus-highlight-color: #{$oc-blue-2};
  --icon-fill-color: var(--color-gray-80);
  --icon-green-fill-color: #{$oc-green-9};
  --default-bg-color: #{$oc-white};
  --input-bg-color: #{$oc-white};
  --input-border-color: #{$oc-gray-4};
  --input-hover-bg-color: #{$oc-gray-1};
  --input-label-color: #{$oc-gray-7};
  --island-bg-color: rgba(255, 255, 255, 0.96);
  --keybinding-color: var(--color-gray-40);
  --link-color: #{$oc-blue-7};
  --overlay-bg-color: #{transparentize($oc-white, 0.12)};
  --popup-bg-color: #{$oc-white};
  --popup-secondary-bg-color: #{$oc-gray-1};
  --popup-text-color: #{$oc-black};
  --popup-text-inverted-color: #{$oc-white};
  --select-highlight-color: #{$oc-blue-5};
  --shadow-island: 0px 7px 14px rgba(0, 0, 0, 0.05),
    0px 0px 3.12708px rgba(0, 0, 0, 0.0798),
    0px 0px 0.931014px rgba(0, 0, 0, 0.1702);
  --button-hover-bg: var(--color-gray-10);
  --default-border-color: var(--color-gray-30);

  --default-button-size: 2rem;
  --default-icon-size: 1rem;
  --lg-button-size: 2.25rem;
  --lg-icon-size: 1rem;
  --editor-container-padding: 1rem;

  @media screen and (min-device-width: 1921px) {
    --lg-button-size: 2.5rem;
    --lg-icon-size: 1.25rem;
    --default-button-size: 2.25rem;
    --default-icon-size: 1.25rem;
  }

  --scrollbar-thumb: var(--button-gray-2);
  --scrollbar-thumb-hover: var(--button-gray-3);

  --modal-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07),
    0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198),
    0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275),
    0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035),
    0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725),
    0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
  --avatar-border-color: var(--color-gray-20);
  --sidebar-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07),
    0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198),
    0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275),
    0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035),
    0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725),
    0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
  --sidebar-border-color: var(--color-gray-20);
  --sidebar-bg-color: #fff;
  --library-dropdown-shadow: 0px 15px 6px rgba(0, 0, 0, 0.01),
    0px 8px 5px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.09),
    0px 1px 2px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);

  --space-factor: 0.25rem;
  --text-primary-color: var(--color-gray-80);

  --color-selection: #6965db;

  --color-icon-white: #{$oc-white};

  --color-primary: #6965db;
  --color-primary-darker: #5b57d1;
  --color-primary-darkest: #4a47b1;
  --color-primary-light: #e3e2fe;
  --color-primary-light-darker: #d7d5ff;

  --color-gray-10: #f5f5f5;
  --color-gray-20: #ebebeb;
  --color-gray-30: #d6d6d6;
  --color-gray-40: #b8b8b8;
  --color-gray-50: #999999;
  --color-gray-60: #7a7a7a;
  --color-gray-70: #5c5c5c;
  --color-gray-80: #3d3d3d;
  --color-gray-85: #242424;
  --color-gray-90: #1e1e1e;
  --color-gray-100: #121212;

  --color-warning: #fceeca;
  --color-warning-dark: #f5c354;
  --color-warning-darker: #f3ab2c;
  --color-warning-darkest: #ec8b14;
  --color-text-warning: var(--text-primary-color);

  --color-danger: #db6965;
  --color-danger-dark: #db6965;
  --color-danger-darker: #d65550;
  --color-danger-darkest: #d1413c;
  --color-danger-text: black;

  --color-danger-background: #fff0f0;
  --color-danger-icon-background: #ffdad6;
  --color-danger-color: #700000;
  --color-danger-icon-color: #700000;

  --color-warning-background: var(--color-warning);
  --color-warning-icon-background: var(--color-warning-dark);
  --color-warning-color: var(--text-primary-color);
  --color-warning-icon-color: var(--text-primary-color);

  --color-muted: var(--color-gray-30);
  --color-muted-darker: var(--color-gray-60);
  --color-muted-darkest: var(--color-gray-100);
  --color-muted-background: var(--color-gray-80);
  --color-muted-background-darker: var(--color-gray-100);

  --color-promo: #e70078;
  --color-success: #268029;
  --color-success-lighter: #cafccc;

  --border-radius-md: 0.375rem;
  --border-radius-lg: 0.5rem;

  &.theme--dark {
    &.theme--dark-background-none {
      background: none;
    }
  }

  &.theme--dark {
    --theme-filter: #{$theme-filter};
    --button-destructive-bg-color: #5a0000;
    --button-destructive-color: #{$oc-red-3};

    --button-gray-1: #363636;
    --button-gray-2: #272727;
    --button-gray-3: #222;
    --button-special-active-bg-color: #204624;
    --dialog-border-color: var(--color-gray-80);
    --dropdown-icon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="292.4" height="292.4" viewBox="0 0 292 292"><path fill="%23ced4da" d="M287 197L159 69c-4-3-8-5-13-5s-9 2-13 5L5 197c-3 4-5 8-5 13s2 9 5 13c4 4 8 5 13 5h256c5 0 9-1 13-5s5-8 5-13-1-9-5-13z"/></svg>');
    --focus-highlight-color: #{$oc-blue-6};
    --icon-fill-color: var(--color-gray-40);
    --icon-green-fill-color: #{$oc-green-4};
    --default-bg-color: #121212;
    --input-bg-color: #121212;
    --input-border-color: #2e2e2e;
    --input-hover-bg-color: #181818;
    --input-label-color: #{$oc-gray-2};
    --island-bg-color: #262627;
    --keybinding-color: var(--color-gray-60);
    --link-color: #{$oc-blue-4};
    --overlay-bg-color: #{transparentize($oc-gray-8, 0.88)};
    --popup-bg-color: #2c2c2c;
    --popup-secondary-bg-color: #222;
    --popup-text-color: #{$oc-gray-4};
    --popup-text-inverted-color: #2c2c2c;
    --select-highlight-color: #{$oc-blue-4};
    --text-primary-color: var(--color-gray-40);
    --button-hover-bg: var(--color-gray-80);
    --default-border-color: var(--color-gray-80);
    --shadow-island: 0px 13px 33px rgba(0, 0, 0, 0.07),
      0px 4.13px 9.94853px rgba(0, 0, 0, 0.0456112),
      0px 1.13px 4.13211px rgba(0, 0, 0, 0.035),
      0px 0.769896px 1.4945px rgba(0, 0, 0, 0.0243888);
    --modal-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07),
      0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198),
      0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275),
      0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035),
      0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725),
      0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
    --avatar-border-color: var(--color-gray-85);
    --sidebar-border-color: var(--color-gray-85);
    --sidebar-bg-color: #191919;

    --scrollbar-thumb: #{$oc-gray-8};
    --scrollbar-thumb-hover: #{$oc-gray-7};

    // will be inverted to a lighter color.
    --color-selection: #3530c4;

    --color-icon-white: var(--color-gray-90);

    --color-primary: #a8a5ff;
    --color-primary-darker: #b2aeff;
    --color-primary-darkest: #beb9ff;
    --color-primary-light: #4f4d6f;
    --color-primary-light-darker: #43415e;

    --color-text-warning: var(--color-gray-80);

    --color-danger: #ffa8a5;
    --color-danger-dark: #672120;
    --color-danger-darker: #8f2625;
    --color-danger-darkest: #ac2b29;
    --color-danger-text: #fbcbcc;

    --color-danger-background: #fbcbcc;
    --color-danger-icon-background: #672120;
    --color-danger-color: #261919;
    --color-danger-icon-color: #fbcbcc;

    --color-warning-background: var(--color-warning);
    --color-warning-icon-background: var(--color-warning-dark);
    --color-warning-color: var(--color-gray-80);
    --color-warning-icon-color: var(--color-gray-80);

    --color-muted: var(--color-gray-80);
    --color-muted-darker: var(--color-gray-60);
    --color-muted-darkest: var(--color-gray-20);
    --color-muted-background: var(--color-gray-40);
    --color-muted-background-darker: var(--color-gray-20);

    --color-promo: #d297ff;
  }
}
